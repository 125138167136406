var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-form",
        { staticClass: "mt-2", attrs: { inline: "" } },
        [
          _c("b-form-input", {
            staticClass: "i-100",
            attrs: { trim: "", placeholder: _vm.$t("SEARCH"), v: "" },
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
          _c(
            "b-form-select",
            {
              staticClass: "ml-md-2 mt-sm-2",
              attrs: { name: "nodes_limit" },
              on: { change: _vm.getNodes },
              model: {
                value: _vm.limit,
                callback: function ($$v) {
                  _vm.limit = $$v
                },
                expression: "limit",
              },
            },
            [
              _c("b-form-select-option", { attrs: { value: "10" } }, [
                _vm._v("10"),
              ]),
              _c("b-form-select-option", { attrs: { value: "25" } }, [
                _vm._v("25"),
              ]),
              _c("b-form-select-option", { attrs: { value: "45" } }, [
                _vm._v("45"),
              ]),
              _c("b-form-select-option", { attrs: { value: "100" } }, [
                _vm._v("100"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("b-table", {
            key: _vm.nodesKey,
            attrs: {
              bordered: "",
              items: _vm.items,
              hover: "",
              small: "",
              fields: _vm.fields,
            },
            on: { "row-clicked": _vm.clickedRow },
            scopedSlots: _vm._u([
              {
                key: "cell(id)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(data.item.description) + "\n      "
                    ),
                  ]
                },
              },
              {
                key: "cell(node_type)",
                fn: function (data) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("LICENSE." + data.item.node_type)) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-pagination", {
            staticClass: "mt-2",
            attrs: {
              "total-rows": _vm.totalItems,
              "per-page": _vm.limit,
              align: "center",
              pills: "",
            },
            on: { input: _vm.getNodes },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }