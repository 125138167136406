<template>
  <section class="mt-2">
    
    <b-form inline class="mt-2">
      <b-form-input trim
        class="i-100"
        v-model="searchText"
        :placeholder="$t('SEARCH')"
        v
      ></b-form-input>
      <b-form-select  name="nodes_limit" v-model="limit" @change="getNodes" class="ml-md-2 mt-sm-2">
        <b-form-select-option value="10">10</b-form-select-option>
        <b-form-select-option value="25">25</b-form-select-option>
        <b-form-select-option value="45">45</b-form-select-option>
        <b-form-select-option value="100">100</b-form-select-option>
      </b-form-select>
    </b-form>

    <div class="mt-2">
      <b-table
        bordered
        :key="nodesKey"
        :items="items"
        hover
        small
        :fields="fields"
        @row-clicked="clickedRow"
      >
        <template v-slot:cell(id)="data">
          {{ data.item.description }}
        </template>

        <template v-slot:cell(node_type)="data">
          {{ $t("LICENSE." + data.item.node_type) }}
        </template>
      </b-table>

      <b-pagination
        class="mt-2"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="limit"
        @input="getNodes"
        align="center"
        pills
      >
      </b-pagination>
    </div>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      nodesIndex: 0,
      searchText: "",
      limit: 10,
      show_sidebar: false,
      node: null,
      nodeId: 0,
      nodeIndex: 0,
      currentPage: 1,
      items: [],
      totalItems: 0,
      state: 1,
      fields: [
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          key: "organisation_name",
          label: this.$t("ORGANISATION.TITLE"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "description",
          label: this.$t("NAME"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "hostname",
          label: this.$t("HOSTNAME"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "node_type",
          label: this.$t("TYPE"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
      ]
    };
  },
  methods: {
    changeList() {
      this.currentPage = 1;
    },
    updatedNode(data, index) {
      this.items.$set(index, data);
    },
    removedNode(index) {
      this.items.splice(index, 1);
    },
    clickedRow(data) {
      this.$router.push({
        name: "Administration.Node",
        params: { node_id: data.id },
      }).catch(() => {});
    },
    getNodes: function() {
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/node/list",
          {
            page: this.currentPage,
            organisation_id: this.organisation.id,
            limit: parseInt(this.limit),
            state: 1,
            invited: 0,
            search_text: this.searchText,
          }
        )
        .then((response) => {
          self.nodesIndex++;
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
        });
    },
    showState: function(state) {
      if (state == 1) return this.$t("ACTIVE");
      if (state == 2) return this.$t("INACTIVE");
      if (state == 0) return this.$t("INVITED");
    }
  },
  watch: {
    searchText: function () {
      if (this.searchText.length == 0) {
        this.getNodes();
      }
      if (this.searchText.length >= 2) {
        this.getNodes();
      }
    },
  },
  computed: {
    nodesKey(){
      return "nodesKey_" + this.nodesIndex;
    },
    user() {
      return this.$store.state.user;
    }
  },
  mounted: function() {
    this.getNodes();
  },
};
</script>
<style></style>
